@import 'styles/constants';

.wrapper {
  background: url('/images/backgrounds/404-line-375px.svg') 72% calc(100% - 23px) no-repeat;

  @include md-up {
		background: url('/images/backgrounds/404-line-768px.svg') calc(50% + 38px) calc(100% - 16px) no-repeat;
	}

  @include lg-up {
		background-position-x: calc(50% + 44px);
		background-position-y: calc(100% - 14px);
	}

  @include xl-up {
    background-position-x: calc(50% + 62px);
	}
}

.wrapper-inner {
  background: url('/images/backgrounds/404-illustration-375px.svg') 100% 39px no-repeat;
  min-height: 464px;
  padding-top: indent(5);
  padding-bottom: indent(3);

  @include md-up {
    min-height: 664px;
		background: url('/images/backgrounds/404-illustration-768px.svg') 100% calc(100% - 42px) no-repeat;
    padding-bottom: indent(2);
	}

  @include xl-up {
    min-height: 680px;
		background-position-x: calc(100% - 95px);
	}
}

.error-content {
  @include md-up {
    margin-top: 40px;
  }
}