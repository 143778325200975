@import './_variables';
@import '../../node_modules/bootstrap/scss/functions';

@import '../../node_modules/bootstrap/scss/mixins';

@mixin input-spaces {
	margin: indent(2) 0 indent(1);
}

@mixin reset-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

@mixin hit-slop($size: 40px, $pos: before) {
	position: relative;
	&:#{$pos} {
		content: '';
		display: block;
		width: $size;
		height: $size;
		position: absolute;
		top: 50%;
		left: 50%;
		background: transparent;
		transform: translate(-50%, -50%);
	}
}

@function color($color-name) {
	@return map-get($colors, $color-name);
}

@function fs($font-size) {
	@return map-get($font-sizes, $font-size);
}

@function breakpoint($point-size) {
	@return map-get($grid-breakpoints, $point-size);
}

@function breakpoint-container($point-size) {
	@return map-get($container-max-widths, $point-size);
}

@function indent($size) {
	@return $size * $spacer;
}

@mixin text-one-row {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin xxl-up {
	@media screen and (min-width: breakpoint(xxl)) {
		@content;
	}
}

@mixin xl-up {
	@media screen and (min-width: breakpoint(xl)) {
		@content;
	}
}
@mixin lg-up {
	@media screen and (min-width: breakpoint(lg)) {
		@content;
	}
}

@mixin lg-down {
	@media screen and (max-width: breakpoint(lg)) {
		@content;
	}
}

@mixin md-up {
	@media screen and (min-width: breakpoint(md)) {
		@content;
	}
}

@mixin md-down {
	@media screen and (max-width: breakpoint(md)) {
		@content;
	}
}

@mixin sm-up {
	@media screen and (min-width: breakpoint(sm)) {
		@content;
	}
}

@mixin xxs-up {
	@media screen and (min-width: breakpoint(xxs)) {
		@content;
	}
}

@mixin xs-up {
	@media screen and (min-width: breakpoint(xs)) {
		@content;
	}
}

@mixin landings-why {
	margin-top: indent(3);
	padding: indent(3) 0 indent(5);

	@include md-up {
		margin-top: indent(8);
		padding: indent(3) 0;
	}
}

@mixin landings-section {
	padding-top: indent(3);

	@include md-up {
		padding-top: indent(8);
	}
}
